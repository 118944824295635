import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles, Typography, Container } from "@material-ui/core"

import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  test: {
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  mainContainer: {
    padding: "6%",
  },
}))

const links = [
  {
    name: "قائمة إصدارات دار الغوثاني للدراسات القرآنية 2021",
    link: "https://gwthani.com/books/Files/قائمة إصدارات دار الغوثاني للدراسات القرآنية 2021.xlsx",
  },
  {
    name: "إصدارات دار الغوثاني للدراسات القرآنية",
    link: "https://gwthani.com/books/Files/إصدارات دار الغوثاني للدراسات القرآنية.pdf",
  },
]

const GetBrochure = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "get-brochure",
        `/${i18n.language}/get-brochure`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "get-brochure",
          `/${i18n.language}/get-brochure`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/get-brochure`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("header.getBrochurePage"),
    },
  ]

  return (
    <>
      <SEO title={t("header.getBrochurePage")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("header.getBrochurePage")} />
      <Container className={classes.mainContainer}>
        {/* {links.map((item, index) => (<Typography>{item.link} - {index + 1} </Typography>))} */}
        {links.map((item, index) => (
          <Typography key={index}>
            {index + 1} - <a href={item.link} target="_blanck" >{item.name}</a>
          </Typography>
        ))}
        {/* <Typography variant="h2" component="h2">
          {" "}
          {t("test.toosoon")}{" "}
        </Typography> */}
      </Container>
    </>
  )
}
export default GetBrochure
